









import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  components: {
  }
})
export default class ContactForm extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  is_alert: boolean

  email = ''
  tel = ''
  name = ''

  loading = false

  get isH5() {
    return AppModule.isH5
  }


  submitForm() {
    if (!this.tel && !this.email) {
      alert('請填寫您的聯繫電話或郵箱地址')
      ;(this.$refs.email as HTMLInputElement).focus()
      return false
    }
    if (!this.name) {
      alert('請填寫您的稱呼')
      ;(this.$refs.name as HTMLInputElement).focus()
      return false
    }

    const xhr = new XMLHttpRequest()
    // prod
    // xhr.open('get', `https://meal.pin2eat.com/home/send-consult-email?email=${this.email}&tel=${this.tel}&name=${this.name}`)
    xhr.open('get', `https://meal.pin2eat.com/send-consult-email?email=${this.email}&tel=${this.tel}&name=${this.name}`)
    // dev
    // xhr.open('get', `https://sandbox-meal.pin2eat.com/send-consult-email?email=${this.email}&tel=${this.tel}&name=${this.name}`)
    xhr.send()
    this.loading = true
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          try {
            const res = JSON.parse(xhr.response)
            if (res && res.code === 200) {
              this.email = this.tel = this.name = ''
              if (this.is_alert) {
                alert('提交成功')
              }
              this.$emit('submit-success')
            }
          } catch {
            alert('提交失敗，請刷新後重試')
          }
        }
      }
      this.loading = false
    }
  }
}
